/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import './scss/style.scss';
import { useKeycloak } from '@react-keycloak/web';
import loadingIndicator from './views/LoadingIndicator';
import { useSettingsQuery } from './generated/graphql';
import CAVErrorBox from './views/tools/CAVErrorBox';

import store, { DBGlobalSettings } from './store';

const LoginPage = React.lazy(() => import('./views/login/LoginPage'));
const LoginFailedPage = React.lazy(() => import('./views/loginfailed/LoginFailedPage'));
const LogoutPage = React.lazy(() => import('./views/logout/LogoutPage'));
const PublicCoursePage = React.lazy(() => import('./views/publicCourse/PublicCoursePage'));

interface IProps {
    exact?: boolean;
    path: string;
    name?: string;
    component: React.ComponentType<any>;
}

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

export const App = (): JSX.Element => {
    const { keycloak, initialized } = useKeycloak();
    const { data, loading, error } = useSettingsQuery();

    const isAuthenticated = () => {
        return keycloak.authenticated;
    };

    const userRole = (): Array<string> => {
        const tokenParsed = keycloak.tokenParsed as {
            resource_access?: { cav2?: { roles?: Array<string> }; osccav2: { roles?: Array<string> } };
        };

        let roles = tokenParsed?.resource_access?.cav2?.roles;
        if (!roles) {
            roles = tokenParsed?.resource_access?.osccav2?.roles;
        }
        if (Array.isArray(roles)) {
            return roles;
        }
        return [];
    };

    const UnauthenticatedRoute = ({ component: Component, ...rest }: IProps) => (
        <Route {...rest} render={(props) => (!isAuthenticated() ? <Component {...props} /> : <Redirect to="/" />)} />
    );

    const AuthenticatedRoute = ({ component: Component, ...rest }: IProps) => (
        <Route
            {...rest}
            render={(props) => {
                if (isAuthenticated()) {
                    if (userRole().includes('webuser')) {
                        return <Component {...props} />;
                    } else {
                        return <Redirect to="/loginfailed" />;
                    }
                } else {
                    return <Redirect to="/login" />;
                }
            }}
        />
    );

    if (!initialized || loading) {
        return loadingIndicator;
    }

    if (error || !data) {
        return <CAVErrorBox error={error?.message} />;
    }

    if (data && data.settings && data.settings.nodes) {
        const settings = data.settings.nodes;
        settings.forEach((setting) => {
            if (setting) {
                switch (setting.key) {
                    case 'globals':
                        const globals = setting.value as DBGlobalSettings;
                        store.dispatch({ type: 'set', clientid: globals.clientid });
                        store.dispatch({ type: 'set', certificatesDisabled: globals.certificatesDisabled });
                        break;
                }
            }
        });
    }

    const clientid = store.getState().clientid;

    if (clientid === 'unknown') {
        return <CAVErrorBox error="Client ID not set" />;
    }

    return (
        <BrowserRouter>
            <React.Suspense fallback={loadingIndicator}>
                <Switch>
                    <Route path="/kurse" component={PublicCoursePage} />
                    <Route path="/kursgruppe/:shortcut" component={PublicCoursePage} />
                    <Route path="/kursdetails/:shortcut/:courseNodeId" component={PublicCoursePage} />
                    <Route path="/kursanmeldung/:courseScheduleId" component={PublicCoursePage} />
                    <Route path="/kursanmeldungemail/:courseScheduleIdSignup" component={PublicCoursePage} />
                    <Route path="/kursanmeldungeingang/:courseScheduleIdSignupDone" component={PublicCoursePage} />
                    <Route path="/kurscode/:code/" component={PublicCoursePage} />
                    <Route exact path="/loginfailed" component={LoginFailedPage} />
                    <UnauthenticatedRoute exact path="/logout" component={LogoutPage} />
                    <UnauthenticatedRoute exact path="/login" component={LoginPage} />
                    <AuthenticatedRoute path="/" component={TheLayout} />
                </Switch>
            </React.Suspense>
        </BrowserRouter>
    );
};
