/* eslint-disable react/display-name */
import React from 'react';
import { CAlert } from '@coreui/react';

export interface CAVErrorConfig {
    error: string | undefined;
}

const CAVErrorBox = (config: CAVErrorConfig): JSX.Element | null => {
    const { error } = config;
    if (!error) return null;
    return (
        <>
            <div className="mt-5">
                <CAlert color="danger">
                    <span>{error ?? 'Unbekannter Fehler'}</span>
                </CAlert>
            </div>
        </>
    );
};

export default CAVErrorBox;
